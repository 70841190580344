/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 6; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */

.circle img {
  transition: 1s ease;
}
  
.circle img:hover {
  border-radius:50%;
  transition: 1s ease;
}

.banner-slice {
  width: 1000;
  height: 250;
  padding: 5px;
}

.avatar {
  width: 200;
  height: 200;
  padding: 5px;
}

.link-newtab {
  target: _blank;
}
